<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技金牌赞助“第三届中国Rust开发者大会”
              </p>
              <div class="fst-italic mb-2">2023年6月21日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >Rust开发者大会</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/41/01-rust大会.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    6月17-18日，由Rust中文社区主办的“第三届中国Rust开发者大会”在上海圆满举行。非凸科技作为金牌赞助商，全力协助大会顺利开展，共同为中国 Rustaceans带来一场技术交流盛宴。
                  </p>
                  <p>
                    本次大会演讲主题内容广泛，涉及编程语言、量化金融、数据库、操作系统、区块链、教育等各个领域的应用实践。非凸科技首席架构师乔丹受邀出席“Rust教育与商业实践”分论坛，就“Rust在算法交易中的实际应用与积极效应”进行了主题演讲。
                  </p>
                  <p>
                    从算法交易的逻辑架构切入，乔丹表示“从行情接入到模型训练、在线预测，从桌面客户端到交易执行服务端等，Rust在非凸算法交易服务中应用广泛，在低延迟方面也做到了极致。”非凸科技数据总线采用自研的进程间通信框架XSHM，基于共享内存、全用户态、零拷贝的一写多读消息队列，满足交易系统低延迟、高吞吐的特征需求。另外，非凸科技采用Rust FFI方式进行行情、交易API的接入，以及对UDP组播进行了网络协议栈参数优化、多路行情冗余互备等。桌面客户端和风控服务端，涵盖了异常的事前、事中、事后的全方位监控，是基于Rust异步编程框架开发，风控逻辑可插拔，兼顾部署灵活性与执行性能。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/41/02-乔丹.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    非凸科技在模型训练及在线预测方面也做了一些优化应用。关于预测，森林模型展开成Rust代码后，编译后的执行效能比动态加载的方式更快；关于互联网新闻因子，大规模的新闻实时spider以及NLP分析，将其做成交易信号的增强来指导交易。而这些训练都建立在非凸科技自建超算机房，配备8TB内存+8显卡定制机型，分布式超大规模的LSTM等基础上支持全天候多周期预测。“在非凸科技，工程师和策略研究员都要写Rust，原因是离线回测、在线模拟盘、实盘策略代码统一，可以降低多栈维护成本以及代码细节不一致导致的实验失真。”乔丹补充道。
                  </p>
                  <p>
                    非凸科技在开源项目上也在不断发力，持续迭代。其中，高性能异步日志库ftlog，支持时间切分、受限写入、异步写入、按业务多目标写入等实用功能。未来，非凸科技将持续创新，开发一种基于Rust宏的语言，即RTL（Rust Trading Language），进一步提升策略研究、回测和实盘的迭代效率；以及开发一种在浏览器内基于Rust + WebAssembly 的高性能、可视化工具，帮助策略研究员极大地提高迭代效率。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/41/03-采访.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    在大会期间，非凸科技有幸采访到Rust中文社区开拓者张汉东老师以及一些Rust爱好者，感谢送来祝福！非凸科技也将持续精进技术，积极探索实践，为Rust发展贡献一份力量，期待Rust开发者大会越办越好！
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News41",
};
</script>

<style></style>
